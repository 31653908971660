/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    IMAGE_TYPE, PLACEHOLDER_TYPE, THUMBNAIL_KEY, VIDEO_TYPE
} from 'Component/ProductGallery/ProductGallery.config';
import { VIDEO_POPUP_ID } from 'Component/VideoPopup/VideoPopup.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer
} from 'SourceRoute/ProductPage/ProductPage.container';
import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import { showPopup } from 'Store/Popup/Popup.action';
import ProductReducer from 'Store/Product/Product.reducer';
import { withReducers } from 'Util/DynamicReducer';
import { getAttributesWithValues } from 'Util/Product';
import { convertQueryStringToKeyValuePairs } from 'Util/Url';

import AmastyFaqReducer from '../../../packages/amasty-faq/src/store/AmastyFaq.reducer';

/** @namespace Scandipwa/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    contentAfterProductDetails: state.ProductReducer?.product?.attributes?.contentAfterProductDetails?.attribute_value
});

/** @namespace Scandipwa/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showVideoPopup: (payload) => dispatch(showPopup(VIDEO_POPUP_ID, payload))
});

/** @namespace Scandipwa/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {
    static propTypes = {
        ...this.propTypes,
        showVideoPopup: PropTypes.func.isRequired,
        contentAfterProductDetails: PropTypes.string,
        isGiftCard: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isGiftCard: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        onPlayClick: this.onPlayClick.bind(this),
        getAreDetailsLoaded: this.getAreDetailsLoaded.bind(this)
    };

    state = {
        ...this.state,
        currentProduct: {},
        isActiveProductChanged: false
    };

    /**
     * Overridden to exclude updating breadcrumbs if giftcard prop is set
     */
    updateBreadcrumbs() {
        const {
            updateBreadcrumbs,
            location,
            isGiftCard
        } = this.props;
        const { state: { prevCategoryId = null } = {} } = location;

        if (isGiftCard) {
            return;
        }

        updateBreadcrumbs(this.getCurrentProductDataSource(), prevCategoryId);
    }

    // Overriden to update state of activeProduct from query params
    // modified to only update state if needed
    static getDerivedStateFromProps(props, state) {
        const {
            product: {
                sku,
                variants,
                configurable_options,
                options,
                productOptionsData
            },
            location: { search }
        } = props;

        const {
            activeProduct: prevActiveProduct = {},
            currentProduct: prevCurrentProduct = {},
            parameters: prevParameters = {},
            currentProductSKU: prevSKU,
            productOptionsData: prevOptionData = {}
        } = state;

        const currentProductSKU = prevSKU === sku ? '' : prevSKU;

        /**
         * If the product we expect to load is loaded -
         * reset expected SKU
         */
        if (
            !configurable_options
            && !variants
        ) {
            if (prevSKU !== currentProductSKU) {
                return {
                    currentProductSKU
                };
            }

            return {};
        }

        const parameters = Object.entries(convertQueryStringToKeyValuePairs(search))
            .reduce((acc, [key, value]) => {
                if (key in configurable_options) {
                    return { ...acc, [key]: value };
                }

                return acc;
            }, {});

        if (Object.keys(parameters).length !== Object.keys(configurable_options).length) {
            const stateToUpdate = {};

            if (prevSKU !== currentProductSKU) {
                stateToUpdate.currentProductSKU = currentProductSKU;
            }

            if (JSON.stringify(prevParameters) !== JSON.stringify(parameters)) {
                stateToUpdate.parameters = parameters;
            }

            return stateToUpdate;
        }

        const searchAttrs = convertQueryStringToKeyValuePairs(search);

        const newProduct = variants.find(
            ({ attributes }) => Object.entries(searchAttrs).every(
                ([key, value]) => attributes[key].attribute_value === value
            )
        ) || {};

        const newOptionsData = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const prevRequiredOptions = productOptionsData?.requiredOptions || [];
        const requiredOptions = [...prevRequiredOptions, ...newOptionsData];

        const stateToUpdate = {};

        if (JSON.stringify(prevParameters) !== JSON.stringify(parameters)) {
            stateToUpdate.parameters = parameters;
        }

        const newProductOptionsData = {
            ...prevOptionData, ...productOptionsData, requiredOptions
        };

        if (JSON.stringify(prevOptionData) !== JSON.stringify(newProductOptionsData)) {
            stateToUpdate.productOptionsData = newProductOptionsData;
        }

        if (newProduct && Object.keys(newProduct).length) {
            const { sku: newSku } = newProduct;

            stateToUpdate.isActiveProductChanged = prevSKU !== newSku;

            if (JSON.stringify(prevCurrentProduct) !== JSON.stringify(newProduct)) {
                stateToUpdate.currentProduct = newProduct;
            }

            if (prevSKU !== newSku) {
                stateToUpdate.currentProductSKU = newSku;
            }

            if (prevActiveProduct?.id !== newProduct.id) {
                stateToUpdate.activeProduct = newProduct;
            }

            return stateToUpdate;
        }

        if (prevSKU !== currentProductSKU) {
            stateToUpdate.currentProductSKU = currentProductSKU;
        }

        return stateToUpdate;
    }

    isProductInformationTabEmpty() {
        const { activeProduct } = this.state;

        const { description: { html = '' } = {} } = activeProduct || this.getDataSource();
        // handling cases when empty html tag is received
        const htmlElement = new DOMParser().parseFromString(html, 'text/html');

        return !htmlElement?.body?.innerHTML;
    }

    isProductAttributesTabEmpty() {
        const { activeProduct } = this.state;

        if (activeProduct) {
            return Object.keys(getAttributesWithValues(activeProduct) || {}).length === 0;
        }

        const dataSource = this.getDataSource();

        return Object.keys(getAttributesWithValues(dataSource) || {}).length === 0;
    }

    /**
     * Override to deprioritize product request
     */
    requestProduct() {
        const { requestProduct, productSKU } = this.props;
        const { currentProductSKU } = this.state;

        /**
         * If URL rewrite was not passed - do not request the product.
         */
        if (!productSKU) {
            return;
        }

        /**
         * Skip loading the same product SKU the second time
         */
        if (currentProductSKU === productSKU) {
            return;
        }

        this.setState({ currentProductSKU: productSKU });

        if (!window.isPrefetchValueUsed) {
            const options = {
                isSingleProduct: true,
                args: { filter: this.getProductRequestFilter() }
            };

            requestProduct(options);
        }
    }

    getCurrentProductDataSource() {
        const { currentProduct } = this.state;
        const product = this.getDataSource();

        if (!Object.keys(currentProduct)?.length || !product) {
            return product;
        }

        const { attributes: productAttr = {}, media_gallery_entries: mediaGallery = [] } = product;
        const { attributes: activeAttr = {}, media_gallery_entries: activeMediaGallery = [] } = currentProduct;

        const attributes = {};

        Object.keys(activeAttr).forEach((attr) => {
            const { [attr]: { attribute_value: attrValue = null, attribute_options = {} } = {} } = productAttr;
            const { [attr]: { attribute_value: activeAttrValue } = {}, [attr]: currAttr } = activeAttr;

            attributes[attr] = {
                ...currAttr,
                attribute_options,
                attribute_value: activeAttrValue || attrValue
            };
        });

        return {
            ...product,
            attributes,
            media_gallery_entries: activeMediaGallery.length === 0 ? mediaGallery : activeMediaGallery
        };
    }

    /**
     * New method to get .ProductGallery as the whish list button's container on mobile
     */
    getWishlistContainerElement() {
        const { isMobile } = this.props;

        if (!isMobile) {
            return null;
        }

        return document.querySelector('.ProductGallery');
    }

    containerProps() {
        const { contentAfterProductDetails } = this.props;
        const { currentProduct, isActiveProductChanged } = this.state;

        return {
            ...super.containerProps(),
            wishlistContainerElement: this.getWishlistContainerElement(),
            contentAfterProductDetails,
            currentProduct: Object.keys(currentProduct).length
                ? currentProduct
                : this.getActiveProductDataSource(),
            currentProductDataSource: this.getCurrentProductDataSource(),
            isActiveProductChanged
        };
    }

    /**
     * New method to get first media with video type of product media_gallery_entries to be used as content on pop-up
     * after clicking PDP "View video" button
     */
    getVideo() {
        const {
            product: {
                media_gallery_entries: mediaGallery = [],
                [THUMBNAIL_KEY]: { url: thumbnailUrl } = {},
                [IMAGE_TYPE]: { url: imageTypeUrl } = {}
            }
        } = this.props;
        const url = imageTypeUrl || thumbnailUrl;

        if (mediaGallery.length) {
            const video = mediaGallery
                .find((media) => {
                    const { disabled, media_type } = media;
                    const isVideoType = media_type === VIDEO_TYPE;
                    const isTrue = !disabled && isVideoType;

                    return isTrue;
                });

            return video;
        }

        if (!url) {
            return { media_type: PLACEHOLDER_TYPE };
        }

        return null;
    }

    /**
     * New method to open video pop-up with media returned from getVideo method after clicking PDP "View video" button
     */
    onPlayClick(event) {
        const video = this.getVideo();
        const {
            video: {
                video_content: {
                    video_title
                } = {}
            } = {},
            showVideoPopup
        } = this.props;

        event.preventDefault();
        event.stopPropagation();

        showVideoPopup({
            media: video,
            title: video_title
        });
    }

    _addToRecentlyViewedProducts() {
        const {
            product,
            product: { sku, type_id },
            addRecentlyViewedProduct,
            store
        } = this.props;
        const { activeProduct: childProduct } = this.state;

        // necessary for skipping not loaded products
        if (!sku) {
            return;
        }

        // push into localstorage only preview of product (image, name and etc)

        /* eslint-disable no-unused-vars */
        const {
            canonical_url,
            categories,
            description,
            items,
            meta_description,
            meta_keyword,
            meta_title,
            options,
            product_links,
            reviews,
            short_description,
            ...productPreview
        } = product;
        /* eslint-enable no-unused-vars */

        if (type_id !== 'configurable') {
            addRecentlyViewedProduct(productPreview, store);

            return;
        }

        if (!childProduct) {
            return;
        }

        const { id } = childProduct;

        const { pathname, search = '' } = location;

        const newProduct = {
            ...productPreview,
            parentUrl: pathname,
            childId: id,
            childParams: search,
            childProduct
        };

        addRecentlyViewedProduct(newProduct, store);
    }
}

export default withReducers({
    ProductReducer,
    BreadcrumbsReducer,
    AmastyFaqReducer
})(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer)
));
