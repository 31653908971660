Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/seo-suite/src/plugin/ProductPageComponent.plugin.js'),require('/drone/src/build/code/scandipwa/packages/magedlight-look-n-buy/src/plugin/ProductPage.plugin.js'),require('/drone/src/build/code/scandipwa/packages/new-clerk/src/plugin/powerstep/Product.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amasty-rma/src/plugin/ProductPage/ProductPageTab.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amasty-automatic-related-products/src/plugin/ProductPage/AddRelatedProductsToProductPage.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amasty-automatic-related-products/src/plugin/ProductPage/BundlePacks.plugin.js'),require('/drone/src/build/code/scandipwa/packages/mageworx-ultimate-sales-boost/src/plugin/SocialProof.plugin.js'),require('/drone/src/build/code/scandipwa/packages/magefan-blog/src/plugin/PDP.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amasty-gift-cards/src/plugin/PDP/ProductPage.plugin.js'),require('/drone/src/build/code/scandipwa/packages/amasty-faq/src/plugin/AddProductQuestionsTab.plugin.js')]);
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/base-ProductReviewListtheme
 */

import PropTypes from 'prop-types';
import { lazy, PureComponent, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import { REVIEW_POPUP_ID } from 'Component/ProductReviews/ProductReviews.config';
import NoMatchHandler from 'Route/NoMatchHandler';
import {
    PRODUCT_ATTRIBUTES,
    PRODUCT_INFORMATION,
    PRODUCT_REVIEWS
} from 'Route/ProductPage/ProductPage.config';
import { RELATED, UPSELL } from 'Store/LinkedProducts/LinkedProducts.config';
import { ProductType } from 'Type/ProductList.type';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import './ProductPage.style';

export const ProductActions = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-actions" */
    'Component/ProductActions'
));

export const ProductGallery = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-gallery" */
    'Component/ProductGallery'
));
export const ProductReviews = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-reviews" */
    'Component/ProductReviews'
));
export const ProductAttributes = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-attributes" */
    'Component/ProductAttributes'
));
export const ProductTabs = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-tabs" */
    'Component/ProductTabs'
));
export const Popup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "popup" */
    'Component/Popup'
));
export const ProductInformation = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-information" */
    'Component/ProductInformation'
));
export const ProductLinks = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-links" */
    'Component/ProductLinks'
));
export const ProductReviewForm = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-review-form" */
    'Component/ProductReviewForm'
));

/** @namespace Route/ProductPage/Component */
export class ProductPage extends PureComponent {
    static propTypes = {
        getLink: PropTypes.func.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        dataSource: ProductType.isRequired,
        activeProduct: ProductType.isRequired,
        areDetailsLoaded: PropTypes.bool.isRequired,
        isInformationTabEmpty: PropTypes.bool.isRequired,
        isAttributesTabEmpty: PropTypes.bool.isRequired,
        setActiveProduct: PropTypes.func.isRequired,
        useEmptyGallerySwitcher: PropTypes.bool.isRequired,
        isVariant: PropTypes.bool.isRequired,
        areReviewsEnabled: PropTypes.bool.isRequired
    };

    tabMap = {
        [PRODUCT_INFORMATION]: {
            name: __('About'),
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key) => this.renderProductInformationTab(key)
        },
        [PRODUCT_ATTRIBUTES]: {
            name: __('Details'),
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key) => this.renderProductAttributesTab(key)
        },
        [PRODUCT_REVIEWS]: {
            name: __('Reviews'),
            shouldTabRender: () => {
                const { areReviewsEnabled } = this.props;

                return areReviewsEnabled;
            },
            render: (key) => this.renderProductReviewsTab(key)
        }
    };

    renderProductPageContent() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            activeProduct,
            setActiveProduct,
            useEmptyGallerySwitcher,
            parameters,
            isVariant
        } = this.props;

        return (
            <>
                <Suspense fallback={ null }>
                    <ProductGallery
                      product={ activeProduct }
                      areDetailsLoaded={ areDetailsLoaded }
                      isWithEmptySwitcher={ useEmptyGallerySwitcher }
                      showLoader={ isVariant }
                    />
                </Suspense>
                <Suspense fallback={ null }>
                    <ProductActions
                      getLink={ getLink }
                      product={ dataSource }
                      parameters={ parameters }
                      areDetailsLoaded={ areDetailsLoaded }
                      setActiveProduct={ setActiveProduct }
                    />
                </Suspense>
            </>
        );
    }

    renderProductInformationTab(key) {
        const {
            dataSource,
            parameters,
            areDetailsLoaded
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <ProductInformation
                  product={ { ...dataSource, parameters } }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    renderProductAttributesTab(key) {
        const {
            activeProduct,
            areDetailsLoaded
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                <ProductAttributes
                  product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    renderProductReviewsTab(key) {
        const {
            dataSource,
            areDetailsLoaded
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                <ProductReviews
                  product={ dataSource }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    shouldTabsRender() {
        return Object.entries(this.tabMap)
            .map(([id, values]) => ({ id, ...values }))
            .filter(({ shouldTabRender }) => shouldTabRender());
    }

    renderProductTabs() {
        const tabs = this.shouldTabsRender();

        if (!tabs) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <ProductTabs tabs={ tabs } />
            </Suspense>
        );
    }

    renderAdditionalSections() {
        const {
            areDetailsLoaded
        } = this.props;

        return (
            <>
                { this.renderProductTabs() }
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ RELATED }
                      title={ __('Recommended for you') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ UPSELL }
                      title={ __('You might also like') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
            </>
        );
    }

    renderReviewPopup() {
        const { dataSource } = this.props;

        return (
            <Suspense fallback={ null }>
                <Popup
                  id={ REVIEW_POPUP_ID }
                  mix={ { block: 'ProductReviews', elem: 'Popup' } }
                >
                    <ProductReviewForm product={ dataSource } />
                </Popup>
            </Suspense>
        );
    }

    render() {
        return (
            <NoMatchHandler>
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                      label={ __('Main product details') }
                    >
                        { this.renderProductPageContent() }
                    </ContentWrapper>
                    { this.renderAdditionalSections() }
                    { this.renderReviewPopup() }
                </main>
            </NoMatchHandler>
        );
    }
}

export default ProductPage;
