/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import { createRef, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import PopupStandard from 'Component/PopupStandard';
import ProductReviewRating from 'Component/ProductReviewRating';
import { REVIEW_POPUP_ID } from 'Component/ProductReviews/ProductReviews.config';
import { RecentlyViewedWidget } from 'Component/WidgetFactory/WidgetFactory.component';
import NoMatchHandler from 'Route/NoMatchHandler';
import {
    PRODUCT_REVIEWS
} from 'Route/ProductPage/ProductPage.config';
import { RELATED, UPSELL } from 'Store/LinkedProducts/LinkedProducts.config';
import { ProductType } from 'Type/ProductList.type';
import { noopFn } from 'Util/Common';
import { withReducers } from 'Util/DynamicReducer';
import { AfterPriority, lowPriorityLazy, setLoadedFlag } from 'Util/Request/LowPriorityLoad';

// eslint-disable-next-line max-len
import AmastyAutomaticRelatedProductsReducer from '../../../packages/amasty-automatic-related-products/src/store/AmastyAutomaticRelatedProducts/AmastyAutomaticRelatedProducts.reducer';
import AmastyFaqReducer from '../../../packages/amasty-faq/src/store/AmastyFaq.reducer';
import {
    Popup,
    ProductActions,
    ProductAttributes,
    ProductGallery,
    ProductInformation,
    ProductLinks,
    ProductPage as SourceProductPage,
    ProductReviewForm,
    ProductReviews,
    ProductTabs
} from './ProductPage.source.component';

import './ProductPage.override.style';

export const Pickup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "click-and-collect" */
    'Component/Pickup'
));
export const StoreAvailability = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "click-and-collect" */
    'Component/StoreAvailability'
));
export const BlackPlayIcon = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/BlackPlayIcon'
));
export const WarrantyIcon = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/WarrantyIcon'
));

export {
    Popup,
    PopupStandard,
    ProductActions,
    ProductAttributes,
    ProductGallery,
    ProductInformation,
    ProductLinks,
    ProductReviewForm,
    ProductReviews,
    ProductTabs
};

/** @namespace Scandipwa/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPage {
    static propTypes = {
        ...this.propTypes,
        onPlayClick: PropTypes.func,
        currentProduct: ProductType.isRequired,
        isActiveProductChanged: PropTypes.bool.isRequired,
        currentProductDataSource: ProductType.isRequired
    };

    static defaultProps = {
        onPlayClick: noopFn
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getNecessaryTabsFromSourceTabMap() {
        // vvv removing the reviews tab from tabs without overriding otherwise plugins break
        const tabs = this.tabMap;
        // eslint-disable-next-line no-unused-vars
        const { [PRODUCT_REVIEWS]: reviewsTab, ...restTabs } = tabs;

        return restTabs;
    }

    tabMap = {
        ...this.getNecessaryTabsFromSourceTabMap()
    };

    productReviewsRef = createRef();

    renderViewVideoButton = this.renderViewVideoButton.bind(this);

    renderProductInformationTab(key) {
        const {
            parameters,
            getAreDetailsLoaded,
            currentProduct
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <ProductInformation
                  product={ { ...currentProduct, parameters } }
                  areDetailsLoaded={ getAreDetailsLoaded() }
                  key={ key }
                />
            </Suspense>
        );
    }

    /**
     * New method to render custom attribute
     */
    renderContentAfterProductDetails() {
        const {
            activeProduct: {
                content_after_product_details: contentAfterProductDetails = '',
                attributes: {
                    contentAfterProductDetails: {
                        attribute_value
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!contentAfterProductDetails && !attribute_value) {
            return null;
        }

        return (
            <ContentWrapper
              mix={ {
                  block: 'ProductPage',
                  elem: 'ContentAfterProductDetails'
              } }
            >
                <Html
                  content={ attribute_value || contentAfterProductDetails }
                  isContentAfterProductDetails
                />
            </ContentWrapper>
        );
    }

    /**
     * Overridden to add product reviews,
     * and remove product tabs,
     * Add RecentlyViewedWidget
     *
     * Return value's two elements are wrapped in a fragment,
     * as added function was interfering with related product plugin
     *
     */
    renderAdditionalSections() {
        const {
            dataSource,
            areDetailsLoaded
        } = this.props;

        return (
            <>
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ UPSELL }
                      title={ __('You might also like') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
                <Suspense fallback={ null }>
                    <ProductReviews
                      product={ dataSource }
                      areDetailsLoaded={ areDetailsLoaded }
                      productReviewsRef={ this.productReviewsRef }
                    />
                </Suspense>
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ RELATED }
                      title={ __('Recommended for you') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
                <Suspense fallback={ null }>
                    <RecentlyViewedWidget />
                </Suspense>
            </>
        );
    }

    /**
     * New method to render "View video" button
     */
    renderViewVideoButton() {
        const { onPlayClick, currentProduct: { media_gallery_entries } } = this.props;
        const isVideoPresent = media_gallery_entries?.some((media) => media.video_content);

        if (!isVideoPresent) {
            return null;
        }

        return (
            <button
              block="ProductPage"
              elem="ViewVideoButton"
              mix={ { block: 'Button', mods: { isHollow: true } } }
              onClick={ onPlayClick }
            >
                <Suspense fallback={ null }>
                    <BlackPlayIcon />
                </Suspense>
                { __('View video') }
            </button>
        );
    }

    /**
     * Overridden to remove ProductActions and add renderViewVideoButton usage
     * Pass the button as props so that it doesn't ruin the sticky function
     */
    renderProductPageContent() {
        const {
            areDetailsLoaded,
            currentProduct,
            useEmptyGallerySwitcher,
            isVariant,
            activeProduct
        } = this.props;

        return (
            <Suspense fallback={ this.renderProductGalleryFallback() }>
                <ProductGallery
                  product={ currentProduct }
                  activeProductForHulla={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  isWithEmptySwitcher={ useEmptyGallerySwitcher }
                  showLoader={ isVariant }
                  isSticky
                  videoButton={ this.renderViewVideoButton }
                  isMainProduct
                  animationDuration={ 500 }
                  isZoomable
                  isPdp
                />
            </Suspense>
        );
    }

    renderProductGalleryFallback() {
        const {
            actionName: {
                name = '',
                imageUrl = ''
            } = {}
        } = window;

        if (!imageUrl) {
            setLoadedFlag();
        }

        const cdnSuffix = '/cdn-cgi/image/width=650,quality=85, format=auto';
        const imageSrc = imageUrl && imageUrl[0] === '/'
            ? `${cdnSuffix}${imageUrl}`
            : `${cdnSuffix}/${imageUrl}`;

        return (
            <>
                <div block="ProductGalleryFallback">
                    <Image
                      alt={ name }
                      src={ imageSrc }
                      ratio="custom"
                      isPlaceholder={ !imageUrl }
                      onImageLoad={ setLoadedFlag }
                    />
                </div>
                <div block="CustomersViewsFallback" elem="Preload" />
                <div block="DesignRoomButtonFallback" />
            </>
        );
    }

    /**
     * New method to render product actions
     */
    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
            wishlistContainerElement
        } = this.props;

        return (
                <Suspense fallback={ this.renderProductActionsFallback() }>
                    <ProductActions
                      getLink={ getLink }
                      product={ dataSource }
                      parameters={ parameters }
                      areDetailsLoaded={ areDetailsLoaded }
                      setActiveProduct={ setActiveProduct }
                      wishlistContainerElement={ wishlistContainerElement }
                      productReviewsRef={ this.productReviewsRef }
                    />
                </Suspense>
        );
    }

    renderProductActionsFallback() {
        const {
            actionName: {
                name = ''
            } = {}
        } = window;

        return (
            <div block="ProductActionsFallback">
                <p block="ProductActionsFallback" elem="Title">
                    { name.toLowerCase() }
                </p>
                <section block="ProductActionsFallback" elem="Sku">
                    <span block="ProductActionsFallback" elem="SkuText">{ __('Code:') }</span>
                    <div block="ProductActionsFallback" elem="SkuNumber" />
                </section>
                <div block="ProductActionsFallback" elem="Reviews">
                    <ProductReviewRating summary={ 0 } count={ 0 } />
                </div>
                <div block="ProductActionsFallback" elem="PriceWrapper" />
                <div block="TabbyPlaceholder" />
                <div block="InstallmentPromoPlaceholder" />
                <div block="OrderBeforeXdeliveryPlaceholder" />
                <div block="ProductAlertsPlaceholder" />
                <div block="SpotProductsPlaceholder" />
                <div block="ProductActionsFallback" elem="AddToCartWrapper">
                    <div block="ProductActionsFallback" elem="Quantity">
                        <div block="ProductActionsFallback" elem="QuantityButton" />
                        <div block="ProductActionsFallback" elem="QuantityButton" />
                    </div>
                    <div block="ProductActionsFallback" elem="Stock" />
                </div>
                <div block="ProductActionsFallback" elem="ActionButtons">
                    <div block="ProductActionsFallback" elem="AddToCart" />
                    <div block="ProductActionsFallback" elem="Wishlist" />
                </div>
            </div>
        );
    }

    renderReviewPopup() {
        const { dataSource } = this.props;

        return (
            <Suspense fallback={ null }>
                <Popup
                  id={ REVIEW_POPUP_ID }
                  mix={ { block: 'ProductReviews', elem: 'Popup' } }
                >
                    <PopupStandard>
                        <Suspense fallback={ null }>
                            <ProductReviewForm product={ dataSource } />
                        </Suspense>
                    </PopupStandard>
                </Popup>
            </Suspense>
        );
    }

    /**
     * New method to render warranty
     */
    renderWarranty() {
        const {
            dataSource: {
                categories = []
            } = {}
        } = this.props;

        const {
            warranty_title,
            warranty_text
        } = categories[categories.length - 1] || {};

        if (!warranty_title && !warranty_text) {
            return null;
        }

        return (
            <div block="ProductPage" elem="Warranty">
                { this.renderWarrantyTitle(warranty_title) }
                { this.renderWarrantyText(warranty_text) }
            </div>
        );
    }

    renderWarrantyTitle(warranty_title) {
        if (!warranty_title) {
            return null;
        }

        return (
            <div block="ProductPage" elem="WarrantyRow">
                <Suspense fallback={ null }>
                    <WarrantyIcon />
                </Suspense>
                <b>{ warranty_title }</b>
            </div>
        );
    }

    renderWarrantyText(warranty_text) {
        if (!warranty_text) {
            return null;
        }

        return (
            <p className="caption">{ warranty_text }</p>
        );
    }

    /**
     * New method to render Click and Collect
     */
    renderPickup() {
        return (
            <Suspense fallback={ null }>
                <Pickup />
            </Suspense>
        );
    }

    /**
     * New method to render Click and Collect
     */
    renderStoreAvailability() {
        return (
            <Suspense fallback={ null }>
                <StoreAvailability />
            </Suspense>
        );
    }

    renderProductAttributesTab(key) {
        const {
            getAreDetailsLoaded,
            currentProductDataSource
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                <ProductAttributes
                  product={ currentProductDataSource }
                  areDetailsLoaded={ getAreDetailsLoaded() }
                  key={ key }
                />
            </Suspense>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    shouldTabsRender() {
        const { isActiveProductChanged } = this.props;
        const newTabs = Object.entries(this.tabMap)
            .map(([id, values]) => ({ id, ...values }))
            .filter(({ shouldTabRender }) => shouldTabRender());

        if (!this.tabs) {
            this.tabs = newTabs;

            return this.tabs;
        }

        const isDifferent = newTabs.some(
            ({ id }, index) => !this.tabs[index] || this.tabs[index].id !== id
        ) || isActiveProductChanged;

        if (isDifferent) {
            this.tabs = newTabs;
        }

        return this.tabs;
    }

    /**
     * Overridden to force redrawing the ProductTabs component depend on product change.
     * If not do so the 'Technical Information' Tab on PDP appears accidentally
     */
    renderProductTabs() {
        const tabs = this.shouldTabsRender();
        const { dataSource } = this.props;

        if (!tabs) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <ProductTabs tabs={ tabs } dataSource={ dataSource } />
            </Suspense>
        );
    }

    render() {
        return (
            <NoMatchHandler>
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                      label={ __('Main product details') }
                    >
                        { this.renderProductPageContent() }
                        <div block="ProductPage" elem="RightContent">
                            { this.renderProductActions() }
                            <AfterPriority fallback={ null }>
                                { this.renderWarranty() }
                            </AfterPriority>
                            { this.renderPickup() }
                            { this.renderStoreAvailability() }
                            { this.renderProductTabs() }
                        </div>
                    </ContentWrapper>
                    <AfterPriority fallback={ null }>
                        { this.renderContentAfterProductDetails() }
                    </AfterPriority>
                    { this.renderAdditionalSections() }
                    { this.renderReviewPopup() }
                </main>
            </NoMatchHandler>
        );
    }
}

export default withReducers({
    AmastyAutomaticRelatedProductsReducer,
    AmastyFaqReducer
})(ProductPageComponent);
