/**
 * Amasty FAQ & Product Questions compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Suspense } from 'react';

import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import AmastyFaqDispatcher from '../store/AmastyFaq.dispatcher';

export const ProductQuestions = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-questions" */
    '../component/ProductQuestions'
));

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    // vvv Add FaqSettings to mapStateToProps
    return {
        ...callback(...args),
        settings: state.ConfigReducer?.getAmFaqSettings
    };
};

export const getFaqQuestionsOnUpdate = async (args, callback, instance) => {
    callback(...args);

    const [prevProps] = args;
    const { product: { id: prevId } = {}, location: { search: prevSearch } = {} } = prevProps || {};
    const {
        product: { id } = {},
        settings: { productPageSize, questionsSort, storeId } = {},
        location: { search } = {}
    } = instance.props;

    if (id !== prevId || search !== prevSearch) {
        const { getCurrentPage, getValidSortBy } = await import('../util/AmastyFAQ');

        const currentPage = getCurrentPage();
        const sortQuestionBy = getValidSortBy(questionsSort);
        const options = {
            currentPage,
            pageSize: productPageSize,
            sort: {
                [sortQuestionBy]: 'ASC'
            },
            filter: {
                product_id: {
                    eq: id
                },
                store_id: {
                    in: [storeId, 0]
                },
                status: {
                    eq: 1
                },
                visibility: {
                    eq: 1
                }
            }
        };

        const { dispatch } = getStore();

        AmastyFaqDispatcher.fetchQuestions(options, dispatch);
    }
};

export const getFaqQuestionsOnMount = async (args, callback, instance) => {
    callback(...args);

    const { getCurrentPage, getValidSortBy } = await import('../util/AmastyFAQ');

    const {
        product: { id = 0 } = {},
        settings: { productPageSize, questionsSort, storeId } = {}
    } = instance.props;
    const currentPage = getCurrentPage();

    const sortQuestionsBy = getValidSortBy(questionsSort);

    const options = {
        currentPage,
        pageSize: productPageSize,
        sort: {
            [sortQuestionsBy]: 'ASC'
        },
        filter: {
            product_id: {
                eq: id
            },
            store_id: {
                in: [storeId, 0]
            },
            status: {
                eq: 1
            },
            visibility: {
                eq: 1
            }
        }
    };

    const { dispatch } = getStore();

    AmastyFaqDispatcher.fetchQuestions(options, dispatch);
};

export const renderProductQuestionsTab = (args, instance) => {
    const {
        areDetailsLoaded,
        settings: {
            isShowProductQuestionsTab
        } = {},
        location
    } = instance.props;

    if (!isShowProductQuestionsTab) {
        return null;
    }

    return (
        <Suspense fallback={ null }>
            <ProductQuestions
              areDetailsLoaded={ areDetailsLoaded }
              key={ args }
              location={ location }
            />
        </Suspense>
    );
};

export const addProductQuestionsToProductPageTabs = (originalMember, instance) => {
    const {
        settings: {
            productQuestionsTabName,
            isShowProductQuestionsTab
        } = {}
    } = instance.props;

    const questions = getStore().getState().AmastyFaqReducer;
    const { total_count: totalCount = 0 } = questions;

    const PRODUCT_QUESTIONS = !productQuestionsTabName
        ? __('product questions (%s)', totalCount)
        : __('%s (%s)', productQuestionsTabName, totalCount);

    const tabMap = {
        ...originalMember,
        [PRODUCT_QUESTIONS]: {
            name: PRODUCT_QUESTIONS,
            shouldTabRender: () => isShowProductQuestionsTab,
            render: (key) => renderProductQuestionsTab(key, instance)
        }
    };

    return tabMap;
};

export const addAmastyFaqSettingsToContainerProps = (args, callback, instance) => {
    const { settings } = instance.props;

    const questions = getStore().getState().AmastyFaqReducer;

    return { ...callback(...args), settings, questions };
};

export default {
    'Route/ProductPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidMount: getFaqQuestionsOnMount,
            componentDidUpdate: getFaqQuestionsOnUpdate,
            containerProps: addAmastyFaqSettingsToContainerProps
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            tabMap: addProductQuestionsToProductPageTabs
        }
    }
};
