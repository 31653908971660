/**
 * Amasty FAQ & Product Questions compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_AMASTY_FAQ_STORE } from './AmastyFaq.config';

/** @namespace Scandiweb/AmastyFaq/Store/AmastyFaq/Action/updateAmastyFaqStore */
export const updateAmastyFaqStore = (state) => ({
    type: UPDATE_AMASTY_FAQ_STORE,
    state
});
