/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { BUNDLE_PACK_POSITION_BELOW, BUNDLE_PACK_POSITION_TAB } from '../../component/BundlePack/BundlePack.config';
import BundlePacks from '../../component/BundlePacks';
// eslint-disable-next-line max-len
import AmastyAutomaticRelatedProductsDispatcher from '../../store/AmastyAutomaticRelatedProducts/AmastyAutomaticRelatedProducts.dispatcher';

export const fetchBundlePacks = (args, callback, instance) => {
    const {
        currentProduct: { id },
        amastyARPConfig: {
            isEnabled
        } = {}
    } = instance.props;

    if (!isEnabled || !id) {
        return callback(...args);
    }

    const store = getStore();
    const { dispatch } = store;
    const { bundlePackMainId } = store.getState().AmastyAutomaticRelatedProductsReducer;

    AmastyAutomaticRelatedProductsDispatcher.fetchBundlePacks(id, bundlePackMainId, dispatch);

    return callback(...args);
};

export const fetchBundlePacksOnUpdate = (args, callback, instance) => {
    const { currentProduct: { id } = {} } = instance.props;

    const [prevProps] = args;

    const { currentProduct: { id: prevId } = {} } = prevProps;

    if (id !== prevId) {
        fetchBundlePacks(args, callback, instance);
    }

    fetchBundlePacks(args, callback, instance);

    return callback(...args);
};

export const addBundlePacksBelowTabs = (args, callback, instance) => {
    const {
        currentProduct: { id },
        amastyARPConfig: { isEnabled, blockPosition = '' } = {}
    } = instance.props;

    if (!isEnabled || blockPosition !== BUNDLE_PACK_POSITION_BELOW) {
        return callback(...args);
    }

    const Component = callback(...args);
    return (
        <>
            { Component }
            <BundlePacks id={ id } />
        </>
    );
};

export const getTabName = (instance) => {
    const { amastyARPConfig: { tabTitle = '' } = {} } = instance.props;
    const title = !tabTitle ? __('Bundle Packs') : __('%s', tabTitle);

    return title;
};

export const renderBundlePacksTab = (key, instance) => {
    const { dataSource: { id } } = instance.props;

    return <BundlePacks key={ key } id={ id } />;
};

export const addBundlePacksToTabs = (originalMembers, instance) => ({
    [getTabName(instance)]: {
        name: getTabName(instance),
        shouldTabRender: () => {
            const {
                amastyARPConfig: {
                    isEnabled,
                    blockPosition = ''
                } = {}
            } = instance.props;

            const { bundlePacks } = getStore().getState().AmastyAutomaticRelatedProductsReducer;

            return isEnabled && bundlePacks.length && blockPosition === BUNDLE_PACK_POSITION_TAB;
        },
        render: (key) => renderBundlePacksTab(key, instance)
    },
    ...originalMembers
});

/**
 * Renamed Plugin to modify the sequence of plugin injection
 */

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap: addBundlePacksToTabs
        },
        'member-function': {
            componentDidMount: fetchBundlePacks,
            componentDidUpdate: fetchBundlePacksOnUpdate,
            renderContentAfterProductDetails: addBundlePacksBelowTabs
        }
    }
};
