/**
 * Amasty FAQ & Product Questions compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import { updateAmastyFaqStore } from './AmastyFaq.action';

/** @namespace Scandiweb/AmastyFaq/Store/AmastyFaq/Dispatcher */
export class AmastyFaqDispatcher {
    fetchTags = async (limit, dispatch) => {
        const { getTags } = await import('../util/AmastyFAQ');

        const tags = await getTags(limit);

        dispatch(updateAmastyFaqStore({ tags }));
    };

    fetchCategories = async (options, dispatch) => {
        const { getCategories } = await import('../util/AmastyFAQ');

        const categories = await getCategories(options);
        // TODO shouldn't come in the first place from BE
        categories.items = categories?.items.filter(({ status }) => status !== 0);

        dispatch(updateAmastyFaqStore({ categories }));
    };

    fetchQuestions = async (options, dispatch) => {
        waitForPriorityLoad().then(
            /** @namespace Scandiweb/AmastyFaq/Store/AmastyFaq/Dispatcher/AmastyFaqDispatcher/waitForPriorityLoad/then */
            async () => {
                const { getQuestions } = await import('../util/AmastyFAQ');

                const questions = await getQuestions(options);

                dispatch(updateAmastyFaqStore({ questions }));
            }
        );
    };
}

export default new AmastyFaqDispatcher();
