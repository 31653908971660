/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/prop-types */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { withReducers } from 'Util/DynamicReducer';

// eslint-disable-next-line max-len
import AmastyAutomaticRelatedProductsReducer from '../../store/AmastyAutomaticRelatedProducts/AmastyAutomaticRelatedProducts.reducer';
import BundlePacks from './BundlePacks.component';

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Component/BundlePacks/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    bundlePacks: state.AmastyAutomaticRelatedProductsReducer.bundlePacks
});

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Component/BundlePacks/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Component/BundlePacks/Container */
export class BundlePacksContainer extends PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired
    };

    containerFunctions = {};

    containerProps() {
        const { bundlePacks } = this.props;

        return {
            bundlePacks
        };
    }

    render() {
        return (
            <BundlePacks
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    AmastyAutomaticRelatedProductsReducer
})(connect(mapStateToProps, mapDispatchToProps)(BundlePacksContainer));
