import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from 'Component/StarIcon/StarIcon.config';
import {
    AddIcon as SourceAddIcon
} from 'SourceComponent/StarIcon/StarIcon.component';

import './StarIcon.override.style';

/** @namespace Scandipwa/Component/StarIcon/Component */
export class StarIconComponent extends SourceAddIcon {
    /*
    * Overridden to change icons in order to match design
    * Disabled cause eslint core issue
    */
    /* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
    getStarPath() {
        const { starFill } = this.props;

        if (starFill === STAR_EMPTY) {
            return (
                <path
                  block="StarIcon"
                  elem="Empty"
                  d="M8.46415 0.787992C8.63415 0.270745 9.36585 0.270745 9.53585 0.787992L11.288 6.11909C11.3642
                    6.35102 11.581 6.50758 11.8252 6.50703L17.4336 6.49422C17.9783 6.49298 18.2082 7.18824 17.77
                    7.51195L13.2346 10.8625C13.0399 11.0063 12.9587 11.2583 13.0327 11.4887L14.7501
                    16.8357C14.9166 17.3541 14.3246 17.7842 13.883 17.4656L9.33 14.1812C9.13296 14.039 8.86704
                    14.039 8.67001 14.1812L4.11696 17.4656C3.67539 17.7842 3.08344 17.3541 3.24993 16.8357L4.96729
                    11.4887C5.04129 11.2583 4.96006 11.0063 4.76541 10.8625L0.229994 7.51195C-0.208189 7.18824
                    0.0216564 6.49298 0.566441 6.49422L6.17485 6.50703C6.41898 6.50758 6.63576 6.35102 6.71199
                    6.11909L8.46415 0.787992Z"
                />
            );
        }

        if (starFill === STAR_HALF_FULL) {
            return (
                <>
                    <path
                      block="StarIcon"
                      elem="Empty"
                      d="M8.46415 0.787992C8.63415 0.270745 9.36585 0.270745 9.53585 0.787992L11.288 6.11909C11.3642
                        6.35102 11.581 6.50758 11.8252 6.50703L17.4336 6.49422C17.9783 6.49298 18.2082 7.18824 17.77
                        7.51195L13.2346 10.8625C13.0399 11.0063 12.9587 11.2583 13.0327 11.4887L14.7501
                        16.8357C14.9166 17.3541 14.3246 17.7842 13.883 17.4656L9.33 14.1812C9.13296 14.039 8.86704
                        14.039 8.67001 14.1812L4.11696 17.4656C3.67539 17.7842 3.08344 17.3541 3.24993 16.8357L4.96729
                        11.4887C5.04129 11.2583 4.96006 11.0063 4.76541 10.8625L0.229994 7.51195C-0.208189 7.18824
                        0.0216564 6.49298 0.566441 6.49422L6.17485 6.50703C6.41898 6.50758 6.63576 6.35102 6.71199
                        6.11909L8.46415 0.787992Z"
                    />
                    <g clipPath="url(#clip0_16538_7824)">
                        <path
                          block="StarIcon"
                          elem="Full"
                          d="M8.46415 0.787992C8.63415 0.270745 9.36585 0.270745 9.53585 0.787992L11.288 6.11909C11.3642
                          6.35102 11.581 6.50758 11.8252 6.50703L17.4336 6.49422C17.9783 6.49298 18.2082 7.18824 17.77
                          7.51195L13.2346 10.8625C13.0399 11.0063 12.9587 11.2583 13.0327 11.4887L14.7501
                          16.8357C14.9166 17.3541 14.3246 17.7842 13.883 17.4656L9.33 14.1812C9.13296 14.039 8.86704
                          14.039 8.67001 14.1812L4.11696 17.4656C3.67539 17.7842 3.08344 17.3541 3.24993 16.8357L4.96729
                          11.4887C5.04129 11.2583 4.96006 11.0063 4.76541 10.8625L0.229994 7.51195C-0.208189 7.18824
                          0.0216564 6.49298 0.566441 6.49422L6.17485 6.50703C6.41898 6.50758 6.63576 6.35102 6.71199
                          6.11909L8.46415 0.787992Z"
                        />
                    </g>
                    <defs xmlns="http://www.w3.org/2000/svg">
                    <clipPath id="clip0_16538_7824">
                    <rect width="9" height="18" fill="white" />
                    </clipPath>
                    </defs>
                </>
            );
        }

        if (starFill === STAR_FULL) {
            return (

                <path
                  block="StarIcon"
                  elem="Full"
                  d="M8.46415 0.787992C8.63415 0.270745 9.36585 0.270745 9.53585 0.787992L11.288 6.11909C11.3642
                  6.35102 11.581 6.50758 11.8252 6.50703L17.4336 6.49422C17.9783 6.49298 18.2082 7.18824 17.77
                  7.51195L13.2346 10.8625C13.0399 11.0063 12.9587 11.2583 13.0327 11.4887L14.7501
                  16.8357C14.9166 17.3541 14.3246 17.7842 13.883 17.4656L9.33 14.1812C9.13296 14.039 8.86704
                  14.039 8.67001 14.1812L4.11696 17.4656C3.67539 17.7842 3.08344 17.3541 3.24993 16.8357L4.96729
                  11.4887C5.04129 11.2583 4.96006 11.0063 4.76541 10.8625L0.229994 7.51195C-0.208189 7.18824
                  0.0216564 6.49298 0.566441 6.49422L6.17485 6.50703C6.41898 6.50758 6.63576 6.35102 6.71199
                  6.11909L8.46415 0.787992Z"
                />

            );
        }

        return null;
    }
    /* eslint-enable @scandipwa/scandipwa-guidelines/only-render-in-component */

    render() {
        return (
            <svg
              block="StarIcon"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                { this.getStarPath() }
            </svg>
        );
    }
}

export default StarIconComponent;
