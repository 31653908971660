/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement, Suspense } from 'react';

import NoMatchHandler from 'Route/NoMatchHandler';
import { UPSELL } from 'Store/LinkedProducts/LinkedProducts.config';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import {
    RELATED_POSITION_BOTTOM,
    RELATED_POSITION_TAB,
    RELATED_POSITION_TOP,
    RELATED_POSITION_UPSELL,
    RELATED_PRODUCT,
    RELATED_TO_CONTENT
} from '../../component/Related/Related.config';
// eslint-disable-next-line max-len
import AmastyAutomaticRelatedProductsDispatcher from '../../store/AmastyAutomaticRelatedProducts/AmastyAutomaticRelatedProducts.dispatcher';
import { getPositions, getProduct } from '../../util/AmastyAutomaticRelatedProducts';

export const Related = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "amasty-arp" */
    '../../component/Related'
));

const renderRelatedWithSuspense = (props) => (
    <Suspense fallback={ null }>
        <Related
          // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
          { ...props }
        />
    </Suspense>
);

export const addRelatedProductsToAdditionalSections = (args, callback, instance) => {
    const {
        dataSource: { id },
        amastyARPConfig: { isEnabled } = {},
        isSignedIn
    } = instance.props;

    if (!isEnabled) {
        return callback(...args);
    }

    const store = getStore();
    const { dispatch } = store;

    AmastyAutomaticRelatedProductsDispatcher.fetchRelatedProducts(id, RELATED_PRODUCT, isSignedIn, dispatch);

    const Component = callback(...args);
    const children = Children.toArray(Component?.props?.children);
    const firstChildren = children?.length ? children[0]?.props?.children : [];

    // vvv Adding related products by position from config
    return (
        <>
            { cloneElement(
                Component,
                Component.props,
                Children.map(
                    firstChildren,
                    (child) => {
                        const props = child.props?.children?.props;

                        if (props?.linkType && props.linkType === RELATED_POSITION_UPSELL) {
                            return (
                                <>
                                    { renderRelatedWithSuspense({ relatedType: RELATED_PRODUCT, position: UPSELL }) }
                                    { child }
                                </>
                            );
                        }

                        return child;
                    }
                )
            ) }
        </>
    );
};

export const addRelatedProductsToRender = (args, callback, instance) => {
    const { amastyARPConfig: { isEnabled } = {} } = instance.props;

    const { relatedProducts } = getStore().getState().AmastyAutomaticRelatedProductsReducer;

    const { positionRelateTo, positionPlace } = getPositions(relatedProducts, RELATED_PRODUCT);

    if (!isEnabled || !positionRelateTo) {
        return callback(...args);
    }

    const Component = callback(...args);
    const children = Children.toArray(Component?.props?.children);

    // vvv Adding related products by position from config
    return (
        <NoMatchHandler>
            { cloneElement(
                Component,
                Component.props,
                Children.map(
                    children,
                    (child, i) => {
                        const lastIndex = children.length
                            ? children.length - 1
                            : 0;

                        if (i === lastIndex) {
                            if (positionRelateTo === RELATED_TO_CONTENT && positionPlace === RELATED_POSITION_BOTTOM) {
                                return (
                                    <>
                                        { child }
                                        { renderRelatedWithSuspense({ relatedType: RELATED_PRODUCT }) }
                                    </>
                                );
                            }

                            return child;
                        }

                        if (positionRelateTo === RELATED_TO_CONTENT && positionPlace === RELATED_POSITION_TOP) {
                            return (
                                <>
                                    { renderRelatedWithSuspense({ relatedType: RELATED_PRODUCT }) }
                                    { child }
                                </>
                            );
                        }

                        return child;
                    }
                )
            ) }
        </NoMatchHandler>
    );
};

export const getTabName = () => {
    const { relatedProducts } = getStore().getState().AmastyAutomaticRelatedProductsReducer;

    const { blockTitle = '' } = getProduct(relatedProducts, RELATED_PRODUCT) || {};

    const title = blockTitle ? __('%s', blockTitle) : __('Who Viewed This Also Viewed');

    return title;
};

export const renderRelatedProductsTab = (key) => renderRelatedWithSuspense({ key, relatedType: RELATED_PRODUCT });

export const addRelatedProductsToTabs = (originalMembers, instance) => ({
    [getTabName(instance)]: {
        name: getTabName(instance),
        shouldTabRender: () => {
            const { relatedProducts } = getStore().getState().AmastyAutomaticRelatedProductsReducer;

            const { positionRelateTo, positionPlace } = getPositions(relatedProducts, RELATED_PRODUCT);

            return !!positionPlace && positionRelateTo === RELATED_TO_CONTENT
                && positionPlace === RELATED_POSITION_TAB;
        },
        render: (key) => renderRelatedProductsTab(key)
    },
    ...originalMembers
});

export const addRelatedProductsToUpsell = (args, callback, instance) => {
    const {
        amastyARPConfig: { isEnabled } = {},
        linkType
    } = instance.props;

    const isRelatedProductsEnabled = (linkType === UPSELL && isEnabled);

    if (!isRelatedProductsEnabled) {
        return callback(...args);
    }

    return renderRelatedWithSuspense({ relatedType: RELATED_PRODUCT, position: linkType });
};

export const addNewStateToTheContainerProps = (args, callback, instance) => {
    const { isSignedIn } = instance.props;

    return {
        ...callback(...args),
        isSignedIn
    };
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        isSignedIn: state.MyAccountReducer?.isSignedIn
    };
};

export default {
    'Route/ProductPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/ProductPage/Container': {
        'member-function': {
            containerProps: addNewStateToTheContainerProps
        }
    },
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap: addRelatedProductsToTabs
        },
        'member-function': {
            // this needs to be removed, as it causes blinking on product re-render
            // render: addRelatedProductsToRender,
            renderAdditionalSections: addRelatedProductsToAdditionalSections
        }
    }
};
