/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { sendViewEvent } from '../../util/tracking';

export const aroundComponentDidUpdate = (args, callback, instance) => {
    const {
        product: {
            id
        }
    } = instance.props;

    const [prevProps] = args;

    const {
        product: {
            id: prevId
        }
    } = prevProps;

    callback(...args);

    if (id && id !== prevId) {
        sendViewEvent({ product: id });
    }
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate: aroundComponentDidUpdate
        }
    }
};
