/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ProductListQuery from 'Query/ProductList.query';
import { showPopup } from 'Store/Popup/Popup.action';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import getStore from 'Util/Store';

import {
    CART_POPUP_ID,
    HUNDRED
} from '../component/BundlePack/BundlePack.config';
import { RELATED_TO_INTO } from '../component/Related/Related.config';
import {
    ARABIC_TO_ENGLISH_NUMBERS,
    ENGLISH_TO_ARABIC_NUMBERS
} from './numbers';

/* eslint-disable max-len */

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/isArabicStore */
export const isArabicStore = () => {
    const storeCode = getStore().getState()?.ConfigReducer?.code;

    return storeCode.endsWith('_ar');
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getEnglishNumber */
export const getEnglishNumber = (arabicNumberString) => Array.from(
    arabicNumberString,
    (arabicDigit) => ARABIC_TO_ENGLISH_NUMBERS[arabicDigit]
).join('');

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getArabicNumber */
export const getArabicNumber = (englishNumberString) => Array.from(
    englishNumberString,
    (englishDigit) => ENGLISH_TO_ARABIC_NUMBERS[englishDigit]
).join('');

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getNewProductIds */
export const getNewProductIds = (productIds, id, isChecked, items) => {
    const { id: mainProductId } = items.find((item) => item.isMainProduct);
    const productIdsArray = productIds.split(',');
    const isMainProductIdInArray = productIdsArray.some(
        (item) => item === mainProductId
    );

    if (!isMainProductIdInArray) {
        productIdsArray.push(mainProductId);
    }

    if (isChecked) {
        const newProductIdsArray = productIdsArray.concat(id);

        return newProductIdsArray.toString();
    }

    const newProductIdsArray = productIdsArray.filter((item) => item !== id);

    return newProductIdsArray.toString();
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getNewProducts */
export const getNewProducts = (product, products, isChecked) => {
    if (isChecked) {
        return products.concat(product);
    }

    const { id: productId } = product;
    const newProducts = products.filter(({ id }) => id !== productId);

    return newProducts;
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/showNewPopup */
export const showNewPopup = (index = '') => {
    const { dispatch } = getStore();

    dispatch(showPopup(CART_POPUP_ID + index, { title: __('Choose Options') }));
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getFloatPrice */
export const getFloatPrice = (price, isInteger = false, productQty = 1) => {
    if (isArabicStore()) {
        const arabicNumberString = price.replace(
            /[^\u0660-\u0669\u066B\u066C]+/g,
            ''
        );

        // eslint-disable-next-line fp/no-let
        let numberString;

        if (!arabicNumberString) {
            numberString = price.replace(/[^\d.]/g, '');

            // In some cases 'price' for arabic products could be in english numbers,
            // so need to account for that with this check
            if (!arabicNumberString && !numberString) {
                return 0;
            }
        }

        const englishNumberString = numberString ?? getEnglishNumber(
            arabicNumberString
        ).replace(',', '');

        if (isInteger) {
            return parseFloat(englishNumberString) * productQty;
        }

        return parseFloat(englishNumberString).toFixed(2) * productQty;
    }

    const englishNumberString = price.replace(/[^\d.]/g, '');

    if (isInteger) {
        return parseFloat(englishNumberString) * productQty;
    }

    return parseFloat(englishNumberString).toFixed(2) * productQty;
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/discountCalculator */
export const discountCalculator = (productObjectArr) => productObjectArr.reduce((acc, item) => {
    const { discountHtml, productPriceHtml, productQty } = item;

    // If there is no discountHtml it means that discount amount is 0, so we shouldn't calculate any discount
    if (!discountHtml) {
        return !Number(acc) ? 0 : acc;
    }

    const sign = discountHtml.replace(/[\d]/g, '');

    const price = getFloatPrice(productPriceHtml, true, productQty);
    if (sign === '%') {
        if (!Number(acc)) {
            return (price * getFloatPrice(discountHtml, true)) / HUNDRED;
        }

        return acc + ((price * getFloatPrice(discountHtml, true)) / HUNDRED);
    }

    if (!Number(acc)) {
        return price === 0 ? 0 : getFloatPrice(discountHtml, true);
    }

    return price === 0 ? 0 : acc + getFloatPrice(discountHtml, true);
}, 0);

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getFinalPriceAndDiscount */
export const getFinalPriceAndDiscount = (
    items,
    childIds,
    finalPriceHtml,
    discountHtml,
    conditionalDiscount,
    applyOnlyForAll
) => {
    // vvv Check for main product
    const { id: parentId, productPriceHtml, productQty } = items.find(
        (item) => item.isMainProduct
    );
    const mainProductPrice = getFloatPrice(productPriceHtml, false, productQty);
    const ids = childIds.split(',');
    // vvv Check for conditional discount limit of products
    const isOverConditionalDiscount = conditionalDiscount > ids.length;
    // vvv If has only one product we need to disable discount
    const isOnlyOne = (ids.length === 1 && ids[0] === parentId) || ids.length === 0;
    // vvv Getting products that was checked
    const checkedProducts = items.filter((item) => {
        const { id } = item;
        const excl = ids.find((item) => item === id);
        if (excl) {
            return item;
        }

        return null;
    });

    const finalDiscountOnlyForAllProducts = applyOnlyForAll && ids.length === items.length
        ? discountCalculator(items)
        : 0;

    const checkedProductsSum = checkedProducts.reduce((acc, item) => {
        const { productPriceHtml, productQty } = item;
        return acc + getFloatPrice(productPriceHtml, true, productQty);
    }, 0.0);

    const finalDiscountSum = applyOnlyForAll
        ? finalDiscountOnlyForAllProducts
        : discountCalculator(checkedProducts);

    // vvv Get final discount
    const fDiscount = Math.abs(finalDiscountSum).toFixed(2);

    const returnedDiscount = isOnlyOne || isOverConditionalDiscount
        ? parseFloat(0).toFixed(2)
        : fDiscount;

    const returnedFinalPrice = isOnlyOne
        ? mainProductPrice
        : (checkedProductsSum - +returnedDiscount).toFixed(2);

    return {
        isOnlyOne,
        returnedDiscount: isArabicStore()
            ? getArabicNumber(returnedDiscount)
            : returnedDiscount,
        returnedFinalPrice: isArabicStore()
            ? getArabicNumber(returnedFinalPrice)
            : returnedFinalPrice,
        isOverConditionalDiscount
    };
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getProducts */
export const getProducts = async (options) => {
    const {
        products: {
            items
        }
    } = await executeGet(
        prepareQuery([ProductListQuery.getQuery(options)]),
        'AmastyARPGetProducts',
        ONE_MONTH_IN_SECONDS
    );

    return items;
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getPositions */
export const getPositions = (relatedProducts, relatedType) => {
    const products = relatedProducts || [];
    const { position = '' } = products.find((item) => item.type === relatedType) || {};

    const positionArray = position.split('_');

    if (!positionArray.length) {
        return {};
    }

    const [, positionRelateTo, positionPlace] = positionArray;

    return { positionRelateTo, positionPlace };
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getPosition */
export const getPosition = (relatedProduct, relatedType) => {
    const { position = '', type } = relatedProduct || {};

    if (type !== relatedType) {
        return {};
    }

    const positionArray = position.split('_');

    if (!positionArray.length) {
        return {};
    }

    const [, positionRelateTo, positionPlace] = positionArray;

    return { positionRelateTo, positionPlace };
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getProductDependsOnPosition */
export const getProductDependsOnPosition = (
    relatedProducts = [],
    relatedType,
    relatedPosition
) => {
    const products = relatedProducts.filter((item) => item.type === relatedType) || [];
    const product = products.find((item) => {
        const { position } = item;
        const positionArray = position.split('_');

        return (
            positionArray[1] === RELATED_TO_INTO
            && positionArray[2] === relatedPosition
        );
    });

    return product || {};
};

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getProduct */
export const getProduct = (relatedProducts = [], relatedType) => {
    const products = relatedProducts || [];

    return products.find(
        (item) => item.type === relatedType
    );
};
