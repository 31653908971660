export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurationData: state.ConfigReducer.seoConfigurationData
    };
};

export class ProductPageContainerPlugin {
    addHreflangTags = (hreflangs) => {
        if (hreflangs) {
            const indexOfArAe = hreflangs.findIndex(({ code }) => (code === 'ar-AE'));
            if (indexOfArAe !== -1) {
                const {
                    url
                } = hreflangs[indexOfArAe];

                if (!url.includes('uae_ar')) {
                    const passHttps = 8;
                    const indexOf = url.indexOf('/', passHttps);
                    // eslint-disable-next-line no-param-reassign
                    hreflangs[indexOfArAe].url = `${url.slice(0, indexOf + 1) }uae_ar/${ url.slice(indexOf + 1)}`;
                }
            }
            hreflangs.forEach((data) => {
                const hreflangTag = document.createElement('link');
                hreflangTag.href = data.url;
                hreflangTag.rel = 'alternate';
                hreflangTag.hreflang = data.code;

                if (!document.querySelector(`[rel="alternate"][hreflang="${data.code}"][href="${data.url}"]`)) {
                    document.getElementsByTagName('head')[0].appendChild(hreflangTag);
                }
            });
        }
    };

    containerProps = (args, callback, instance) => {
        const {
            seoConfigurationData
        } = instance.props;

        return {
            ...callback(...args),
            seoConfigurationData
        };
    };

    componentDidUpdate = (args, cb, instance) => {
        const {
            product: {
                mw_hreflangs
            }
        } = instance.props;
        const [{
            product: { mw_hreflangs: prevMwHreflangs } = {}
        } = {}] = args;

        if (mw_hreflangs && Object.keys(mw_hreflangs).length > 0 && mw_hreflangs !== prevMwHreflangs) {
            this.addHreflangTags(mw_hreflangs.items);
        }

        cb(...args);
    };

    updateMeta= (args, callback, instance) => {
        const {
            updateMetaFromProduct,
            product: {
                mw_canonical_url: { url = '' } = {},
                meta_title: metaTitle,
                meta_description: description
            } = {},
            seoConfigurationData: {
                crop_meta_title: cropMetaTitle,
                crop_meta_description: cropMetaDescription
            } = {}
        } = instance.props;

        const updatedDescription = description && cropMetaDescription
            ? description?.substring(0, cropMetaDescription) : description;
        const updatedTitle = metaTitle && cropMetaTitle ? metaTitle?.substring(0, cropMetaTitle) : metaTitle;

        updateMetaFromProduct({
            ...instance.getDataSource(),
            canonical_url: url,
            meta_title: updatedTitle,
            meta_description: updatedDescription
        });
    };

    componentWillUnmount = (_args, _callback, instance) => {
        const {
            product
        } = instance.props;

        if ('mw_hreflangs' in product && 'items' in product.mw_hreflangs) {
            const {
                mw_hreflangs: {
                    items
                }
            } = product;

            if (items) {
                items.forEach((data) => {
                    const previousHreflang = document.querySelector(
                        `[rel="alternate"][hreflang="${data.code}"][href="${data.url}"]`
                    );

                    if (previousHreflang) {
                        previousHreflang.remove();
                    }
                });
            }
        }
    };
}

const {
    componentDidUpdate,
    componentWillUnmount,
    updateMeta,
    containerProps
} = new ProductPageContainerPlugin();

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate,
            componentWillUnmount,
            updateMeta,
            containerProps
        }
    },
    'Route/ProductPage/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps
            }
        ]
    }
};
