/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrowserDatabase from 'Util/BrowserDatabase';

export const CLERK_VISITOR_KEY = 'clerk_visitor';

/** @namespace Scandiweb/Clerk/Util/Visitor/getVisitor */
export const getVisitor = () => {
    const visitor = BrowserDatabase.getItem(CLERK_VISITOR_KEY);

    if (visitor) {
        return visitor;
    }

    // eslint-disable-next-line no-magic-numbers
    const visitorId = Math.random().toString(36).substring(2, 15);

    BrowserDatabase.setItem(visitorId, CLERK_VISITOR_KEY, Number.POSITIVE_INFINITY);

    return visitorId;
};
