/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import NoMatchHandler from 'Route/NoMatchHandler';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import { AM_GIFTCARD_PRODUCT_TYPE } from '../../component/GiftCardProductActions/GiftCardProductActions.config';

export const GiftCardProductActions = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "gift-card-product-actions" */
    '../../component/GiftCardProductActions'
));

const renderGiftCardProductContent = (args, callback, instance) => {
    const {
        getLink,
        dataSource,
        areDetailsLoaded,
        activeProduct: {
            type_id: typeId,
            id: productId
        },
        setActiveProduct,
        parameters
    } = instance.props;
    const { generalActive } = getStore().getState()?.ConfigReducer?.AmastyGiftCardsConfig || {};

    if (!generalActive) {
        return callback(...args);
    }

    if (typeId !== AM_GIFTCARD_PRODUCT_TYPE) {
        return callback(...args);
    }

    return (
        <NoMatchHandler>
            <Suspense fallback={ <div /> }>
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                      label={ __('Main product details') }
                    >
                        <Suspense fallback={ null }>
                            <GiftCardProductActions
                              getLink={ getLink }
                              product={ dataSource }
                              parameters={ parameters }
                              areDetailsLoaded={ areDetailsLoaded }
                              setActiveProduct={ setActiveProduct }
                              productId={ productId }
                            />
                        </Suspense>
                    </ContentWrapper>
                </main>
            </Suspense>
        </NoMatchHandler>
    );
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render: renderGiftCardProductContent
        }
    }
};
