/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent, Suspense } from 'react';

import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import { ItemType } from '../../type/AmastyAutomaticRelatedProducts.type';

export const ProductBundlePack = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-bundle-pack" */
    '../ProductBundlePack'
));

/** @namespace Scandiweb/AmastyAutomaticRelatedProducts/Component/BundlePacks/Component */
export class BundlePacksComponent extends PureComponent {
    static propTypes = {
        bundlePacks: PropTypes.arrayOf(ItemType).isRequired
    };

    renderPack = (item, idx) => (
        <Suspense fallback={ null }>
            <ProductBundlePack
              key={ item.packId }
              cartBundle={ item }
              index={ idx }
            />
        </Suspense>
    );

    renderPacks() {
        const { bundlePacks } = this.props;

        if (!bundlePacks) {
            return null;
        }

        return bundlePacks.map(this.renderPack);
    }

    render() {
        return (
            <div block="BundlePacks">
                { this.renderPacks() }
            </div>
        );
    }
}

export default BundlePacksComponent;
