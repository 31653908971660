import { PropTypes } from 'prop-types';

import { ONE_FIFTH_OF_A_HUNDRED } from 'Component/ProductReviewRating/ProductReviewRating.config';
import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from 'Component/StarIcon/StarIcon.config';
import {
    ProductReviewRating as SourceProductReviewRating
} from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import './ProductReviewRating.override.style';

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

/** @namespace Scandipwa/Component/ProductReviewRating/Component */
export class ProductReviewRatingComponent extends SourceProductReviewRating {
    static propTypes = {
        ...this.propTypes,
        shouldShowStarsCount: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        shouldShowStarsCount: false
    };

    getAriaText() {
        const {
            summary,
            code,
            count
        } = this.props;

        if (!count) {
            return 'Product has no rating';
        }

        const rating = parseFloat(summary / ONE_FIFTH_OF_A_HUNDRED).toFixed(2);

        return code
            ? `Review's ${code} rating is ${rating} out of 5`
            : `Product's rating is ${rating} out of 5`;
    }

    /**
     * New method to render rating starts count or reviews count on shouldShowStarsCount prop
     */
    renderCount() {
        const {
            count: reviewCount,
            summary,
            shouldShowStarsCount
        } = this.props;

        const startsCount = parseFloat(summary / ONE_FIFTH_OF_A_HUNDRED).toFixed(2).replace(/[.,]00$/, '');

        const ratingCount = shouldShowStarsCount ? startsCount : `(${reviewCount})`;

        const hideRating = (shouldShowStarsCount && !summary) || (!shouldShowStarsCount && !reviewCount);

        if (hideRating) {
            return null;
        }

        return (
            <span block="ProductReviewRating" elem="Counter">
                { ratingCount }
            </span>
        );
    }

    render() {
        const {
            summary,
            placeholder,
            mix
        } = this.props;
        const [fullCount, halfFullCount, emptyCount] = this.getStarCounts();

        if (placeholder) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="ProductReviewRating"
              title={ `${summary}%` }
              ref={ this.reviewRating }
              aria-label={ this.getAriaText() }
              mix={ mix }
            >
                { this.renderStar(fullCount, STAR_FULL) }
                { this.renderStar(halfFullCount, STAR_HALF_FULL) }
                { this.renderStar(emptyCount, STAR_EMPTY) }
                { this.renderCount() }
            </div>
        );
    }
}

export default ProductReviewRatingComponent;
