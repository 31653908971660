/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const ARABIC_TO_ENGLISH_NUMBERS = {
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '٫': '.',
    '٬': ','
};

export const ENGLISH_TO_ARABIC_NUMBERS = {
    0: '٠',
    1: '١',
    2: '٢',
    3: '٣',
    4: '٤',
    5: '٥',
    6: '٦',
    7: '٧',
    8: '٨',
    9: '٩',
    '.': '٫',
    ',': '٬'
};
