/**
 * Magedelight Look n Buy compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Suspense } from 'react';

import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

export const LookNBuyList = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "look-n-buy-list" */
    '../component/LookNBuyList'
));

const addLooksToPdp = (args, callback, instance) => {
    const { dataSource: { id } = {} } = instance.props;

    return (
        <>
            { callback(...args) }
            <Suspense fallback={ null }>
                <LookNBuyList isListOnly productId={ id } isPdp />
            </Suspense>
        </>
    );
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderAdditionalSections: addLooksToPdp
        }
    }
};
